<template>
  <div
    :id="'recipe-' + recipe.id"
    class="recipe-card col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-2"
  >
    <div class="card card-shadow">
      <!-- style="width: 20rem;" -->
      <img class="card-img-top" :src="image" alt="Card image cap" />
      <div class="card-body">
        <div class="card-body-text recipe-card-body-text">
          <h5 class="card-title" v-if="!isSearchResult">{{ recipe.name }}</h5>
          <h5 class="card-title" v-if="isSearchResult">
            <ais-highlight :hit="recipe" attribute="name" />
          </h5>
          <p class="card-text" v-if="!isSearchResult">
            {{ recipe.description }}
          </p>
          <p class="card-text" v-if="isSearchResult">
            <ais-highlight :hit="recipe" attribute="description" />
          </p>
        </div>
        <span class="align-bottom">
          <b-link
            :to="{ name: 'recipe-view', params: { slug: recipe.slug } }"
            class="btn btn-primary"
            >Read</b-link
          >
          <b-link
            :to="{ name: 'recipe-edit', params: { slug: recipe.slug } }"
            class="btn btn-link"
            >Edit</b-link
          >
          <button
            class="btn btn-link"
            v-b-modal="'modal_recipe_delete-' + recipeKey"
          >
            Delete
          </button>
        </span>
      </div>
      <div class="card-footer text-muted">
        Posted by {{ recipe.author ? recipe.author : "Anonymous" }}
        <div class="float-right">
          <button
            class="btn btn-link recipe-report"
            data-toggle="tooltip"
            data-placement="bottom"
            title="Report"
            v-b-modal="'modal_recipe_report-' + recipeKey"
          >
            <i class="fas fa-flag"></i>
          </button>

          <b-modal
            :id="'modal_recipe_report-' + recipeKey"
            title="Report Recipe"
            header-bg-variant="primary"
            header-text-variant="light"
            ok-title="Report"
            ok-variant="primary"
            @ok="submitReport(recipeKey)"
          >
            <form id="frmReport" action="#" method="post">
              <div class="form-group">
                <label for="selReason"
                  >Why are you reporting this content?</label
                >
                <select
                  id="selReason"
                  class="custom-select"
                  v-model="report.reason"
                >
                  <option value="PROFANE">Inappropriate/profane</option>
                  <option value="SPAM">Spam</option>
                  <option value="NOT_RECIPE">Not a recipe</option>
                </select>
              </div>
              <div class="form-group">
                <label for="txtDetail">Please explain:</label>
                <textarea
                  id="txtDetail"
                  class="form-control"
                  type="text"
                  placeholder="e.g. Recipe contains profanity..."
                  rows="6"
                  v-model="report.detail"
                ></textarea>
              </div>
            </form>
          </b-modal>

          <b-modal
            :id="'modal_recipe_report_ty-' + recipeKey"
            title="Report Recipe"
            header-bg-variant="primary"
            header-text-variant="light"
            ok-title="Close"
            ok-variant="primary"
            ok-only
          >
            <p>
              Thank you for your report. It has been received and will be
              reviewed by an administrator shortly.
            </p>
          </b-modal>

          <b-modal
            :id="'modal_recipe_delete-' + recipeKey"
            title="Delete Recipe"
            header-bg-variant="danger"
            header-text-variant="light"
            ok-title="Delete"
            ok-variant="danger"
            @ok="deleteRecipe(recipeKey)"
          >
            <p>
              Are you sure you want to delete recipe {{ recipe.name }}? This
              cannot be undone.
            </p>
          </b-modal>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import FirebaseMixin from "@/mixins/Firebase";
import UtilsMixin from "@/mixins/Utils";

export default {
  name: "RecipeCard",
  props: {
    recipe: {
      type: Object,
      required: true
    }
  },
  mixins: [FirebaseMixin, UtilsMixin],
  components: {},
  data() {
    return {
      ref: "recipes",
      bind: [],
      tags: [],
      placeholder: require("@/assets/recipe-placeholder.jpg"),
      image: "",
      report: {
        reason: "",
        detail: ""
      }
    };
  },
  computed: {
    ...mapState(["isAuthenticated", "userProfile"]),
    familyKeys() {
      if (this.isSearchResult) {
        return this.recipe.familyKeys;
      } else {
        return [this.recipe.family_key];
      }
    },
    canEdit() {
      if (
        this.isAuthenticated &&
        this.familyKeys.includes(this.userProfile.family_key)
      ) {
        return true;
      } else {
        return false;
      }
    },
    isSearchResult() {
      return this.recipe.objectID ? true : false;
    },
    recipeKey() {
      return this.isSearchResult ? this.recipe.objectID : this.recipe[".key"];
    }
  },
  watch: {
    recipe: {
      immediate: true,
      handler(recipe) {
        this.image = this.placeholder;
        if (recipe.image && recipe.image.length > 0) {
          this.getImageUrl("recipes/" + recipe.image).then(url => {
            this.image = url;
          });
        }
      }
    }
  },
  methods: {
    submitReport(recipeKey) {
      const _this = this;
      let report = { ...this.report };
      report.user_uid = this.userProfile.uid;
      this.createObject("reports/" + recipeKey, report)
        .then(() => {
          _this.$bvModal.show("modal_recipe_report_ty-" + recipeKey);
        })
        .catch(error => {
          console.log(error);
          window.toastr.error(
            "An error occurred while submitting the report. Please try again later."
          );
        });
    },
    deleteRecipe(recipeKey) {
      Promise.all([
        this.deleteObject(
          "families/" + this.userProfile.family_key + "/recipes",
          recipeKey
        ),
        this.deleteObject("public_recipes", recipeKey)
      ])
        .then(() => {
          this.deleteObject(this.ref, recipeKey)
            .then(() => {
              window.toastr.success(
                "The recipe has been deleted successfully."
              );
            })
            .catch(error => {
              console.log(error);
              window.toastr.error(
                "An error occurred while deleting the recipe. Please try again later."
              );
            });
        })
        .catch(error => {
          console.log(error);
          window.toastr.error(
            "An error occurred while deleting the recipe. Please try again later."
          );
        });
    }
  }
};
</script>

<style scoped>
.card-shadow {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -4px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.recipe-card-body-text {
  height: 130px;
  overflow: hidden;
  margin-bottom: 10px;
}

.recipe-row {
  padding-top: 4px;
  padding-left: 9px;
  padding-right: 9px;
}

.recipe-card {
  margin: 0;
  padding: 5px;
  min-width: 20%;
}

.card-img-top {
  height: 150px;
  object-fit: cover;
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-link {
  color: rgb(255, 152, 0);
  font-weight: 500;
}

.recipe-review-avatar {
  width: 100%;
  max-width: 70px;
  height: 70px;
}

.recipe-report {
  padding-top: 0px;
  padding-bottom: 0;
  line-height: 1;
  margin-top: -5px;
  color: #6c757d;
}

.recipe-report:hover {
  color: #5a6268;
}
</style>
