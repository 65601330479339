<template>
  <div>
    <div class="container-fluid" v-if="loading">
      <div class="row">
        <div class="col d-flex justify-content-center">
          <loader class="my-5" color="rgba(255, 152, 0, 0.75)"></loader>
        </div>
      </div>
    </div>
    <div class="container-fluid" v-if="!loading">
      <div class="row">
        <div class="col">
          <div class="card-deck">
            <div class="card card-shadow">
              <div class="card-body">
                <h1 class="card-title">
                  {{ reports.length }}
                </h1>
              </div>
              <div class="card-footer bg-dark text-light">
                Total Reports
              </div>
            </div>
            <div class="card card-shadow">
              <div class="card-body">
                <h1 class="card-title">
                  {{ newReports }}
                </h1>
              </div>
              <div class="card-footer bg-dark text-light">
                New Reports
              </div>
            </div>
            <div class="card card-shadow">
              <div class="card-body">
                <h1 class="card-title">
                  {{ acknowledgedReports }}
                </h1>
              </div>
              <div class="card-footer bg-dark text-light">
                Acknowledged Reports
              </div>
            </div>
            <div class="card card-shadow">
              <div class="card-body">
                <h1 class="card-title">
                  {{ rejectedReports }}
                </h1>
              </div>
              <div class="card-footer bg-dark text-light">
                Rejected Reports
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="card card-shadow mt-3">
            <div class="container-fluid">
              <div class="row">
                <div class="col text-left">
                  <div class="card-body">
                    <b-form inline>
                      <b-dropdown text="Filter" size="sm">
                        <b-dropdown-form>
                          <b-form-checkbox
                            v-model="filters.showAll"
                            :value="true"
                            :unchecked-value="false"
                            class="font-weight-bold mb-1"
                            @change="selectAll"
                          >
                            Show All
                          </b-form-checkbox>
                          <b-form-checkbox
                            v-model="filters.new"
                            :value="true"
                            :unchecked-value="false"
                            @change="handleFilterSelect"
                          >
                            New
                          </b-form-checkbox>
                          <b-form-checkbox
                            v-model="filters.acknowledged"
                            :value="true"
                            :unchecked-value="false"
                            @change="handleFilterSelect"
                          >
                            Acknowledged
                          </b-form-checkbox>
                          <b-form-checkbox
                            v-model="filters.rejected"
                            :value="true"
                            :unchecked-value="false"
                            @change="handleFilterSelect"
                          >
                            Rejected
                          </b-form-checkbox>
                        </b-dropdown-form>
                      </b-dropdown>
                      <b-form-input
                        size="sm"
                        class="ml-2"
                        v-model="searchText"
                        placeholder="Search..."
                      ></b-form-input>
                    </b-form>
                  </div>
                </div>
                <div class="col text-right">
                  <div class="card-body">
                    <b-button
                      variant="outline-success"
                      size="sm"
                      v-b-modal="'modal_recipe_report'"
                      ><i class="fas fa-plus"></i> Create Report</b-button
                    >

                    <b-modal
                      id="modal_recipe_report"
                      title="Report Recipe"
                      header-bg-variant="primary"
                      header-text-variant="light"
                      ok-title="Report"
                      ok-variant="primary"
                      @ok="create"
                    >
                      <form id="frmReport" action="#" method="post">
                        <div class="form-group">
                          <label for="selUser">Submitting User:</label>
                          <autocomplete
                            :search="searchUsers"
                            :get-result-value="getUserValue"
                            :default-value="formatUser(userProfile.uid)"
                            overwrite-class="sender"
                            class="mb-3"
                            placeholder="e.g. John Smith"
                            @change="updateUser"
                          ></autocomplete>
                          <label for="selEntity">Entity:</label>
                          <algolia-autocomplete
                            class="mb-3"
                            @change="updateEntity"
                          ></algolia-autocomplete>
                          <label for="selReason">Reason for report:</label>
                          <select
                            id="selReason"
                            class="custom-select"
                            v-model="report.reason"
                          >
                            <option value="PROFANE"
                              >Inappropriate/profane</option
                            >
                            <option value="SPAM">Spam</option>
                            <option value="NOT_RECIPE">Not a recipe</option>
                          </select>
                        </div>
                        <div class="form-group">
                          <label for="txtDetail">Additional detail:</label>
                          <textarea
                            id="txtDetail"
                            class="form-control"
                            type="text"
                            placeholder="e.g. Recipe contains profanity..."
                            rows="6"
                            v-model="report.detail"
                          ></textarea>
                        </div>
                      </form>
                    </b-modal>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <nav
                    class="navbar navbar-expand-md navbar-light bg-light pt-2 pb-2"
                  >
                    <ul class="navbar-nav mr-auto">
                      <li class="nav-item">
                        <!-- Select all -->
                        <b-button
                          variant="outline-secondary"
                          size="sm"
                          @click="toggleSelected"
                        >
                          <i class="far fa-check-square"></i>
                        </b-button>
                      </li>
                      <li class="nav-item">
                        <!-- Acknowledge -->
                        <b-button
                          variant="outline-success"
                          size="sm"
                          class="ml-2"
                          v-b-modal="'bulkAcknowledge'"
                        >
                          <i class="far fa-check-circle"></i>
                          Acknowledge&nbsp;
                        </b-button>
                        <!-- Reject -->
                        <b-button
                          variant="outline-danger"
                          size="sm"
                          class="ml-2"
                          v-b-modal="'bulkReject'"
                        >
                          <i class="fas fa-ban"></i>
                          Reject&nbsp;
                        </b-button>
                        <!-- Reset -->
                        <b-button
                          variant="outline-secondary"
                          size="sm"
                          class="ml-2"
                          v-b-modal="'bulkReset'"
                        >
                          <i class="fas fa-redo"></i>
                          Reset&nbsp;
                        </b-button>

                        <b-modal
                          id="bulkAcknowledge"
                          title="Acknowledge Report(s)"
                          ok-title="Acknowledge"
                          ok-variant="success"
                          @ok="acknowledgeSelected"
                        >
                          Status for selected reports will be set to
                          Acknowledged.

                          <b-form-textarea
                            v-model="bulkComment"
                            placeholder="Add a comment..."
                            rows="6"
                            max-rows="6"
                            class="mt-3"
                          ></b-form-textarea>
                        </b-modal>

                        <b-modal
                          id="bulkReject"
                          title="Reject Report(s)"
                          ok-title="Reject"
                          ok-variant="danger"
                          @ok="rejectSelected"
                        >
                          Status for selected reports will be set to Rejected.

                          <b-form-textarea
                            v-model="bulkComment"
                            placeholder="Add a comment..."
                            rows="6"
                            max-rows="6"
                            class="mt-3"
                          ></b-form-textarea>
                        </b-modal>

                        <b-modal
                          id="bulkReset"
                          title="Reset Report(s)"
                          ok-title="Reset"
                          ok-variant="warning"
                          @ok="resetSelected"
                        >
                          Status for selected reports will be reset to New.
                        </b-modal>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <b-table
                    hover
                    striped
                    responsive
                    :fields="fields"
                    :items="filteredReports"
                  >
                    <template #cell(select)="data">
                      <b-form-checkbox
                        v-model="selectedReports"
                        :value="data.item['.key']"
                      >
                      </b-form-checkbox>
                    </template>
                    <template #cell(user)="data">
                      <b-link @click="viewUser(data.item)">
                        {{ data.item.user.name }}
                      </b-link>
                    </template>
                    <template #cell(status)="data">
                      <b-badge
                        variant="primary"
                        v-if="data.item.status === 'New'"
                        >New</b-badge
                      >
                      <b-badge
                        variant="success"
                        v-if="data.item.status === 'Acknowledged'"
                        >Acknowledged</b-badge
                      >
                      <b-badge
                        variant="danger"
                        v-if="data.item.status === 'Rejected'"
                        >Rejected</b-badge
                      >
                    </template>
                    <template #cell(entity)="data">
                      <b-link @click="viewEntity(data.item)">
                        {{ data.item.entity.name }}
                      </b-link>
                    </template>
                    <template #cell(actions)="data">
                      <b-button
                        variant="outline-success"
                        size="sm"
                        v-b-modal="'acknowledge' + data.item['.key']"
                        :disabled="data.item.status === 'Acknowledged'"
                        ><i class="far fa-check-circle"></i
                      ></b-button>
                      <b-button
                        variant="outline-danger"
                        size="sm"
                        class="ml-1"
                        v-b-modal="'reject' + data.item['.key']"
                        :disabled="data.item.status === 'Rejected'"
                        ><i class="fas fa-ban"></i
                      ></b-button>
                      <b-button
                        variant="outline-secondary"
                        size="sm"
                        class="ml-1"
                        v-b-modal="'reset' + data.item['.key']"
                        :disabled="data.item.status === 'New'"
                        ><i class="fas fa-redo"></i
                      ></b-button>

                      <b-modal
                        :id="'acknowledge' + data.item['.key']"
                        title="Acknowledge Report"
                        ok-title="Acknowledge"
                        ok-variant="success"
                        @ok="acknowledge(data.item)"
                      >
                        Status for report from {{ data.item.user.name }} will be
                        set to Acknowledged.

                        <b-form-textarea
                          v-model="data.item.comment"
                          placeholder="Add a comment..."
                          rows="6"
                          max-rows="6"
                          class="mt-3"
                        ></b-form-textarea>
                      </b-modal>

                      <b-modal
                        :id="'reject' + data.item['.key']"
                        title="Reject Report"
                        ok-title="Reject"
                        ok-variant="danger"
                        @ok="reject(data.item)"
                      >
                        Status for report from {{ data.item.user.name }} will be
                        set to Rejected.

                        <b-form-textarea
                          v-model="data.item.comment"
                          placeholder="Add a comment..."
                          rows="6"
                          max-rows="6"
                          class="mt-3"
                        ></b-form-textarea>
                      </b-modal>

                      <b-modal
                        :id="'reset' + data.item['.key']"
                        title="Reset Report"
                        ok-title="Reset"
                        ok-variant="warning"
                        @ok="reset(data.item)"
                      >
                        Status for report from {{ data.item.user.name }} will be
                        reset to New.
                      </b-modal>
                    </template>
                  </b-table>
                </div>
                <div class="col profile-tab" v-if="showEdit">
                  <b-form>
                    <b-tabs
                      content-class="mt-3 mx-3"
                      class="mb-5"
                      fill
                      v-model="activeTab"
                    >
                      <b-tab title="User">
                        <div class="form-wrapper text-center">
                          <div class="chip-xl my-5">
                            <img
                              :src="report.user.avatar"
                              alt="Person"
                              width="96"
                              height="96"
                            />
                          </div>
                          <h4>{{ report.user.name }}</h4>
                          <small class="text-muted">{{
                            report.user.type
                          }}</small>
                          <div class="user-details">
                            <ul>
                              <li
                                class="d-flex justify-content-between align-items-center"
                              >
                                <small class="text-muted"
                                  ><i class="far fa-clock"></i></small
                                ><small class="text-muted">{{
                                  report.user.signup_date
                                }}</small>
                              </li>
                              <li
                                class="d-flex justify-content-between align-items-center"
                              >
                                <small class="text-muted"
                                  ><i class="far fa-envelope"></i></small
                                ><small class="text-muted">{{
                                  report.user.email
                                }}</small>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </b-tab>
                      <b-tab title="Entity">
                        <recipe-card
                          v-if="report.entity.type === 'recipe'"
                          :recipe="report.entity"
                          :key="report.entity.key"
                          class="entity-card"
                        ></recipe-card>
                      </b-tab>
                      <template #tabs-end>
                        <li
                          role="presentation"
                          class="nav-item align-self-center justify-content-end text-right"
                        >
                          <b-button
                            variant="outline-danger"
                            @click="closeEdit"
                            size="sm"
                            class="mr-1"
                            ><i class="far fa-window-close"></i
                          ></b-button>
                        </li>
                      </template>
                    </b-tabs>
                  </b-form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import FirebaseMixin from "@/mixins/Firebase";
import UtilsMixin from "@/mixins/Utils";
import Loader from "@/components/Loader";
import RecipeCard from "@/components/RecipeCard";
import Autocomplete from "@/components/Autocomplete";
import AlgoliaAutocomplete from "@/components/AlgoliaAutocomplete";
import Users from "@/firebase/users";
import { DateTime } from "luxon";

export default {
  name: "UserReports",
  mixins: [FirebaseMixin, UtilsMixin],
  components: {
    loader: Loader,
    "recipe-card": RecipeCard,
    Autocomplete,
    "algolia-autocomplete": AlgoliaAutocomplete
  },
  data() {
    return {
      bind: [{ ref: "reports", target: "reports" }],
      loading: false,
      saving: false,
      reports: [],
      reportKey: null,
      report: { user: {}, entity: {} },
      selectedReports: [],
      users: [],
      isNew: false,
      fields: [
        {
          key: "select",
          label: "",
          sortable: false
        },
        {
          key: "user",
          label: "User",
          sortable: true
        },
        {
          key: "status",
          label: "Status",
          sortable: true
        },
        {
          key: "entity",
          label: "Reported Entity",
          sortable: true
        },
        {
          key: "reason",
          label: "Reason",
          sortable: true
        },
        {
          key: "detail",
          label: "Detail",
          sortable: false
        },
        {
          key: "comment",
          label: "Comment",
          sortable: false
        },
        {
          key: "actions",
          label: "Actions",
          sortable: false
        }
      ],
      showEdit: false,
      filters: {
        showAll: true,
        new: true,
        acknowledged: true,
        rejected: true
      },
      searchText: "",
      activeTab: 0,
      avatar: require("@/assets/img_avatar.png"),
      placeholder: require("@/assets/recipe-placeholder.jpg"),
      bulkComment: ""
    };
  },
  computed: {
    ...mapState(["isAuthenticated", "userProfile", "config"]),
    ...mapGetters(["searchConfig"]),
    newReports() {
      return this.reports.filter(report => {
        return report.status === "New";
      }).length;
    },
    acknowledgedReports() {
      return this.reports.filter(report => {
        return report.status === "Acknowledged";
      }).length;
    },
    rejectedReports() {
      return this.reports.filter(report => {
        return report.status === "Rejected";
      }).length;
    },
    filteredReports() {
      let reports;
      if (this.searchText.length > 0) {
        const searchText = this.searchText.toLowerCase();
        reports = this.reports.filter(report => {
          return (
            report.user.name.toLowerCase().includes(searchText) ||
            report.status.toLowerCase().includes(searchText) ||
            report.entity.type.toLowerCase().includes(searchText) ||
            report.reason.toLowerCase().includes(searchText) ||
            report.detail.toLowerCase().includes(searchText)
          );
        });
      } else {
        reports = this.reports;
      }
      if (this.filters.showAll) {
        return reports;
      } else {
        return reports.filter(report => {
          let showReport = false;
          if (this.filters.new && report.status === "New") {
            showReport = true;
          }
          if (this.filters.acknowledged && report.status === "Acknowledged") {
            showReport = true;
          }
          if (this.filters.rejected && report.status === "Rejected") {
            showReport = true;
          }
          return showReport;
        });
      }
    }
  },
  watch: {
    reports: {
      immediate: true,
      handler(reports) {
        reports.forEach((report, index) => {
          if (!report.user || typeof report.user !== "object") {
            this.report[index].user = {};
          }
          if (!report.entity || typeof report.entity !== "object") {
            this.report[index].entity = {};
          }
        });
      }
    },
    users: {
      immediate: false,
      handler(users) {
        this.reports.forEach((report, index) => {
          // Add user details
          users.forEach(user => {
            if (report.user.uid === user.auth.uid) {
              let signupDate = DateTime.fromHTTP(
                user.auth.metadata.creationTime
              );
              this.reports[index].user = {
                uid: user.auth.uid,
                name: user.auth.displayName,
                email: user.auth.email,
                signup_date: signupDate.toLocaleString(DateTime.DATE_FULL)
              };
              if ("customClaims" in user.auth) {
                if (user.auth.customClaims.admin) {
                  this.reports[index].user.type = "Administrator";
                } else if (user.auth.customClaims.subscribed) {
                  this.reports[index].user.type = "Premium Member";
                } else {
                  this.reports[index].user.type = "Free Member";
                }
              } else {
                this.reports[index].user.type = "Free Member";
              }
              if ("profile" in user.db && "avatar" in user.db.profile) {
                this.getImageUrl("avatars/" + user.avatar).then(url => {
                  this.reports[index].user.avatar = url;
                });
              } else {
                this.reports[index].user.avatar = this.avatar;
              }
            }
          });
        });
      }
    }
  },
  methods: {
    getUsers() {
      this.loading = true;
      const u = new Users();
      u.getUsers()
        .then(resp => {
          this.users = [...resp.data.users];
          this.loading = false;
        })
        .catch(error => {
          console.error("Unable to fetch users", error);
          this.loading = false;
        });
    },
    searchUsers(input) {
      if (input.length < 1) {
        return [];
      }
      return this.users.filter(user => {
        return (
          user.auth.displayName.toLowerCase().startsWith(input.toLowerCase()) ||
          user.auth.email.toLowerCase().startsWith(input.toLowerCase())
        );
      });
    },
    formatUser(user) {
      if (user && typeof user === "object" && "auth" in user) {
        return user.auth.displayName + " (" + user.auth.email + ")";
      } else {
        const u = this.users[this.getIndex(this.users, user, "uid")];
        if (u && "auth" in u) {
          return u.auth.displayName + " (" + u.auth.email + ")";
        } else {
          return u;
        }
      }
    },
    getUserValue(result) {
      return this.formatUser(result);
    },
    updateUser(value) {
      const user = { ...value };
      if (user && "auth" in user) {
        this.report.user = {
          uid: user.auth.uid,
          name: user.auth.displayName
        };
      }
    },
    updateEntity(value) {
      const entity = { key: value.objectID, name: value.name };
      this.report.entity = entity;
    },
    async acknowledge(report) {
      this.reportKey = report[".key"];
      this.report = { ...report };
      this.report.status = "Acknowledged";
      await this.update();
    },
    async reject(report) {
      this.reportKey = report[".key"];
      this.report = { ...report };
      this.report.status = "Rejected";
      await this.update();
    },
    async reset(report) {
      this.reportKey = report[".key"];
      this.report = { ...report };
      this.report.status = "New";
      this.report.comment = "";
      await this.update();
    },
    async acknowledgeSelected() {
      if (this.selectedReports.length > 0) {
        for (let key of this.selectedReports) {
          let report = this.reports[this.getIndex(this.reports, key)];
          report.comment = this.bulkComment;
          await this.acknowledge(report);
        }
      }
      this.bulkComment = "";
    },
    async rejectSelected() {
      if (this.selectedReports.length > 0) {
        for (let key of this.selectedReports) {
          let report = this.reports[this.getIndex(this.reports, key)];
          report.comment = this.bulkComment;
          await this.reject(report);
        }
      }
      this.bulkComment = "";
    },
    async resetSelected() {
      if (this.selectedReports.length > 0) {
        for (let key of this.selectedReports) {
          let report = this.reports[this.getIndex(this.reports, key)];
          report.comment = this.bulkComment;
          await this.reset(report);
        }
      }
      this.bulkComment = "";
    },
    viewUser(report) {
      if (!report.user || typeof report.user !== "object") {
        report.user = {};
      }
      if (!report.entity || typeof report.entity !== "object") {
        report.entity = {};
      }
      this.reportKey = report[".key"];
      this.report = { ...report };
      this.activeTab = 0;
      this.showEdit = true;
    },
    viewEntity(report) {
      if (!report.user || typeof report.user !== "object") {
        report.user = {};
      }
      if (!report.entity || typeof report.entity !== "object") {
        report.entity = {};
      }
      this.reportKey = report[".key"];
      this.report = { ...report };
      this.activeTab = 1;
      this.showEdit = true;
    },
    closeEdit() {
      this.report = {};
      this.showEdit = false;
    },
    async create() {
      this.saving = true;
      let report = { ...this.report, status: "New" };

      let recipe = await this.getObjectSnapshot("recipes", report.entity.key);
      report.entity = { type: "recipe", key: report.entity.key, ...recipe };

      this.createObject("reports", report)
        .then(() => {
          this.saving = false;
          window.toastr.success("Report created successfully.");
        })
        .catch(error => {
          this.saving = false;
          window.toastr.error(
            "An error occurred creating the report: " + error
          );
          console.error("Unable to create report", error);
        });
      return true;
    },
    update() {
      this.saving = true;
      const report = { ...this.report };
      if (this.reportKey && this.reportKey.length > 0) {
        this.updateObject("reports", this.reportKey, report)
          .then(() => {
            this.saving = false;
            window.toastr.success("Report updated successfully.");
          })
          .catch(error => {
            this.saving = false;
            window.toastr.error(
              "An error occurred updating the report: " + error
            );
            console.error("Unable to update report", this.reportKey, error);
          });
      }
      return true;
    },
    selectAll() {
      for (let filter in this.filters) {
        if (filter !== "showAll") {
          this.filters[filter] = this.filters.showAll;
        }
      }
    },
    handleFilterSelect() {
      let showAll = true;
      for (let filter in this.filters) {
        if (!this.filters[filter] && filter !== "showAll") {
          showAll = false;
        }
      }
      this.filters.showAll = showAll;
    },
    toggleSelected() {
      if (this.selectedReports.length === 0) {
        this.selectedReports = this.reports.map(report => {
          return report[".key"];
        });
      } else {
        this.selectedReports = [];
      }
    }
  },
  created() {
    this.getUsers();
  }
};
</script>

<style>
.entity-card > div {
  box-shadow: none !important;
}

.sender-input {
  height: calc(1.5em + 0.75rem + 2px);
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.sender-input:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.sender-result-list {
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  list-style-type: none;
  cursor: pointer;
}

.sender-result-list:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
</style>

<style scoped>
.card-shadow {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -4px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.background-none {
  background: none;
}

.profile-tab {
  max-width: 600px;
}

.chip-xl {
  display: inline-block;
  padding: 0;
  height: 90px;
  font-size: 14px;
  line-height: 90px;
  border-radius: 45px;
}

.chip-xl img {
  float: left;
  margin: 0;
  height: 90px;
  width: 90px;
  border-radius: 50%;
  object-fit: cover;
  object-position: top;
}

.user-details {
  text-align: center;
  max-width: 250px;
  margin-top: 1.5rem;
  margin-left: auto;
  margin-right: auto;
}

.user-details > ul {
  padding-left: 0;
}

.entity-card {
  width: 300px;
  min-width: 300px;
  margin-left: auto;
  margin-right: auto;
}
</style>
