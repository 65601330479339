<template>
  <div>
    <div class="container-fluid" v-if="loading">
      <div class="row">
        <div class="col d-flex justify-content-center">
          <loader class="my-5" color="rgba(255, 152, 0, 0.75)"></loader>
        </div>
      </div>
    </div>
    <div class="container-fluid" v-if="!loading">
      <div class="row">
        <div class="col">
          <div class="card card-shadow mt-3">
            <div class="container-fluid">
              <div class="row">
                <div class="col text-left">
                  <div class="card-body">
                    <b-form inline>
                      <b-form-input
                        size="sm"
                        v-model="searchText"
                        placeholder="Search..."
                      ></b-form-input>
                    </b-form>
                  </div>
                </div>
                <div class="col text-right">
                  <div class="card-body">
                    <b-button
                      variant="outline-success"
                      size="sm"
                      @click="newRole"
                      ><i class="fas fa-plus"></i> Create Role</b-button
                    >
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <b-table
                    hover
                    striped
                    responsive
                    :fields="fields"
                    :items="filteredRoles"
                  >
                    <template #cell(actions)="data">
                      <b-button
                        variant="outline-primary"
                        size="sm"
                        @click="edit(data.item)"
                        ><i class="fas fa-edit"></i
                      ></b-button>
                      <b-button
                        variant="outline-danger"
                        size="sm"
                        class="ml-1"
                        v-b-modal="'confirmDelete' + data.item['.key']"
                        ><i class="far fa-trash-alt"></i
                      ></b-button>

                      <b-modal
                        :id="'confirmDelete' + data.item['.key']"
                        title="Delete Role"
                        ok-title="Delete"
                        ok-variant="danger"
                        @ok="deleteRole(data.item['.key'])"
                      >
                        Are you sure you want to delete role
                        {{ data.item.name }}? This cannot be undone.
                      </b-modal>
                    </template>
                  </b-table>
                </div>
                <div class="col profile-tab" v-if="showEdit">
                  <b-form @submit.prevent="isNew ? create() : update()">
                    <b-tabs content-class="mt-3 mx-3" class="mb-5" fill>
                      <b-tab title="Profile" active>
                        <div class="form-wrapper">
                          <b-form-group
                            id="input-name"
                            label="Name"
                            label-for="input-name"
                          >
                            <b-form-input
                              id="input-name"
                              v-model="role.name"
                              type="text"
                              required
                              placeholder="e.g. Administrator"
                            ></b-form-input>
                          </b-form-group>
                          <b-form-group
                            id="input-description"
                            label="Description"
                            label-for="input-description"
                          >
                            <b-form-textarea
                              id="text-description"
                              v-model="role.description"
                              placeholder="e.g. Role for administrators"
                              rows="4"
                            ></b-form-textarea>
                          </b-form-group>
                        </div>
                      </b-tab>
                      <b-tab title="Permissions">
                        <div class="form-wrapper">
                          <b-form-group>
                            <b-list-group>
                              <b-list-group-item
                                v-for="permission in permissionList"
                                :key="permission['.key']"
                              >
                                <b-form-checkbox
                                  v-model="role.permissions[permission.name]"
                                  :name="'permission-' + permission.name"
                                  :value="true"
                                  :unchecked-value="false"
                                  switch
                                >
                                  {{ permission.name }}
                                </b-form-checkbox>
                                <p class="mx-1 my-1 text-muted">
                                  {{ permission.description }}
                                </p>
                              </b-list-group-item>
                            </b-list-group>
                          </b-form-group>
                        </div>
                      </b-tab>
                      <template #tabs-end>
                        <li
                          role="presentation"
                          class="nav-item align-self-center justify-content-end text-right"
                        >
                          <b-button
                            type="submit"
                            variant="outline-success"
                            :disabled="saving"
                            size="sm"
                            class="mr-1"
                          >
                            <span v-if="saving">
                              <b-spinner small></b-spinner>
                              <span class="sr-only">Saving...</span>
                            </span>
                            <span v-else>
                              <i class="far fa-save"></i>
                            </span>
                          </b-button>
                          <b-button
                            variant="outline-danger"
                            @click="closeEdit"
                            size="sm"
                            class="mr-1"
                            ><i class="far fa-window-close"></i
                          ></b-button>
                        </li>
                      </template>
                    </b-tabs>
                  </b-form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import FirebaseMixin from "@/mixins/Firebase";
import UtilsMixin from "@/mixins/Utils";
import Loader from "@/components/Loader";

export default {
  name: "Roles",
  mixins: [FirebaseMixin, UtilsMixin],
  components: {
    loader: Loader
  },
  data() {
    return {
      bind: [
        { ref: "roles", target: "roles" },
        { ref: "permissions", target: "permissions" }
      ],
      loading: false,
      saving: false,
      roles: [],
      roleKey: null,
      role: { permissions: {} },
      permissions: [],
      isNew: false,
      fields: [
        {
          key: ".key",
          label: "Key",
          sortable: false
        },
        {
          key: "name",
          label: "Name",
          sortable: true
        },
        {
          key: "description",
          label: "Description",
          sortable: true
        },
        {
          key: "actions",
          label: "Actions"
        }
      ],
      showEdit: false,
      searchText: ""
    };
  },
  computed: {
    ...mapState(["isAuthenticated", "userProfile", "config"]),
    ...mapGetters(["searchConfig"]),
    filteredRoles() {
      if (this.searchText.length > 0) {
        const searchText = this.searchText.toLowerCase();
        return this.roles.filter(role => {
          return (
            role[".key"].toLowerCase().includes(searchText) ||
            role.name.toLowerCase().includes(searchText) ||
            role.description.toLowerCase().includes(searchText)
          );
        });
      } else {
        return this.roles;
      }
    },
    permissionList() {
      let list = [...this.permissions];
      list.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        } else if (b.name < a.name) {
          return 1;
        } else {
          return 0;
        }
      });
      return list;
    }
  },
  watch: {
    roles: {
      immediate: true,
      handler(roles) {
        roles.forEach((role, index) => {
          if (!role.permissions || typeof role.permissions !== "object") {
            this.roles[index].permissions = {};
          }
        });
      }
    }
  },
  methods: {
    edit(role) {
      this.isNew = false;
      if (!role.permissions || typeof role.permissions !== "object") {
        role.permissions = {};
      }
      this.roleKey = role[".key"];
      this.role = { ...role };
      this.showEdit = true;
    },
    closeEdit() {
      this.role = {};
      this.showEdit = false;
    },
    newRole() {
      this.isNew = true;
      this.role = { permissions: {} };
      this.showEdit = true;
    },
    create() {
      this.saving = true;
      const role = { ...this.role };
      this.createObject("roles", role)
        .then(() => {
          this.saving = false;
          window.toastr.success("Role created successfully.");
        })
        .catch(error => {
          this.saving = false;
          window.toastr.error("An error occurred creating the role: " + error);
          console.error("Unable to create role", error);
        });
      return true;
    },
    update() {
      this.saving = true;
      const role = { ...this.role };
      if (this.roleKey && this.roleKey.length > 0) {
        this.updateObject("roles", this.roleKey, role)
          .then(() => {
            this.saving = false;
            window.toastr.success("Role updated successfully.");
          })
          .catch(error => {
            this.saving = false;
            window.toastr.error(
              "An error occurred updating the role: " + error
            );
            console.error("Unable to update role", this.roleKey, error);
          });
      }
      return true;
    },
    deleteRole(key) {
      this.saving = true;
      this.deleteObject("roles", key)
        .then(() => {
          this.saving = false;
          window.toastr.success("Role deleted successfully.");
        })
        .catch(error => {
          this.saving = false;
          window.toastr.error("An error occurred deleting the role: " + error);
          console.error("Unable to delete role", key, error);
        });
      return key;
    }
  },
  created() {}
};
</script>

<style scoped>
.card-shadow {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -4px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.background-none {
  background: none;
}

.profile-tab {
  max-width: 600px;
}
</style>
