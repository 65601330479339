<template>
  <div>
    <div class="container-fluid" v-if="loading">
      <div class="row">
        <div class="col d-flex justify-content-center">
          <loader class="my-5" color="rgba(255, 152, 0, 0.75)"></loader>
        </div>
      </div>
    </div>
    <div class="container-fluid" v-if="!loading">
      <div class="row">
        <div class="col">
          <div class="card-deck">
            <div class="card card-shadow">
              <div class="card-body">
                <h1 class="card-title">
                  {{ users.length }}
                </h1>
              </div>
              <div class="card-footer bg-dark text-light">
                Total Members
              </div>
            </div>
            <div class="card card-shadow">
              <div class="card-body">
                <h1 class="card-title">
                  {{ freeUsers }}
                </h1>
              </div>
              <div class="card-footer bg-dark text-light">
                Free Members
              </div>
            </div>
            <div class="card card-shadow">
              <div class="card-body">
                <h1 class="card-title">
                  {{ subscribers }}
                </h1>
              </div>
              <div class="card-footer bg-dark text-light">
                Premium Members
              </div>
            </div>
            <div class="card card-shadow">
              <div class="card-body">
                <h1 class="card-title">
                  {{ admins }}
                </h1>
              </div>
              <div class="card-footer bg-dark text-light">
                Admins
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="card card-shadow mt-3">
            <div class="container-fluid">
              <div class="row">
                <div class="col text-left">
                  <div class="card-body">
                    <b-form inline>
                      <b-dropdown text="Filter" size="sm">
                        <b-dropdown-form class="text-left">
                          <b-form-checkbox
                            v-model="filters.showAll"
                            :value="true"
                            :unchecked-value="false"
                            class="font-weight-bold mb-1"
                            @change="selectAll"
                          >
                            Show All
                          </b-form-checkbox>
                          <b-form-checkbox
                            v-model="filters.free"
                            :value="true"
                            :unchecked-value="false"
                            @change="handleFilterSelect"
                          >
                            Free Members
                          </b-form-checkbox>
                          <b-form-checkbox
                            v-model="filters.subscribers"
                            :value="true"
                            :unchecked-value="false"
                            @change="handleFilterSelect"
                          >
                            Premium Members
                          </b-form-checkbox>
                          <b-form-checkbox
                            v-model="filters.admins"
                            :value="true"
                            :unchecked-value="false"
                            @change="handleFilterSelect"
                          >
                            Admin
                          </b-form-checkbox>
                        </b-dropdown-form>
                      </b-dropdown>
                      <b-form-input
                        class="ml-2"
                        size="sm"
                        v-model="searchText"
                        placeholder="Search..."
                      ></b-form-input>
                    </b-form>
                  </div>
                </div>
                <div class="col text-right">
                  <div class="card-body">
                    <b-button
                      variant="outline-success"
                      size="sm"
                      @click="newUser"
                      ><i class="fas fa-plus"></i> Create User</b-button
                    >
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <b-table
                    hover
                    striped
                    responsive
                    :fields="fields"
                    :items="filteredUsers"
                  >
                    <template #cell(attributes)="data">
                      <b-badge
                        variant="primary"
                        v-if="data.item.auth.customClaims.premium"
                        >Premium</b-badge
                      >
                      <b-badge
                        variant="primary"
                        v-if="data.item.auth.customClaims.vip"
                        >VIP</b-badge
                      >
                      <b-badge
                        variant="danger"
                        v-if="data.item.auth.customClaims.admin"
                        >Admin</b-badge
                      >
                    </template>
                    <template #cell(actions)="data">
                      <b-button
                        variant="outline-primary"
                        size="sm"
                        @click="edit(data.item)"
                        ><i class="fas fa-edit"></i
                      ></b-button>
                      <b-button
                        variant="outline-danger"
                        size="sm"
                        class="ml-1"
                        v-b-modal="'confirmDelete' + data.item.auth.uid"
                        ><i class="far fa-trash-alt"></i
                      ></b-button>

                      <b-modal
                        :id="'confirmDelete' + data.item.auth.uid"
                        title="Delete User"
                        ok-title="Delete"
                        ok-variant="danger"
                        @ok="deleteUser(data.item.auth.uid)"
                      >
                        Are you sure you want to delete user
                        {{ data.item.auth.displayName }}? This cannot be undone.
                      </b-modal>
                    </template>
                  </b-table>
                </div>
                <div class="col profile-tab" v-if="showEdit">
                  <b-form
                    @submit.prevent="
                      isNew ? $bvModal.show('confirmCreate') : update()
                    "
                  >
                    <b-tabs content-class="mt-3 mx-3" class="mb-5" fill>
                      <b-tab title="Profile" active>
                        <div class="form-wrapper">
                          <b-form-group label="Name" label-for="input-name">
                            <b-form-input
                              id="input-name"
                              v-model="user.auth.displayName"
                              type="text"
                              required
                              placeholder="e.g. John Smith"
                            ></b-form-input>
                          </b-form-group>
                          <b-form-group label="Email" label-for="input-email">
                            <b-form-input
                              id="input-username"
                              v-model="user.auth.email"
                              type="email"
                              required
                              placeholder="e.g. john.smith@qualfon.com"
                            ></b-form-input>
                          </b-form-group>
                          <b-form-group
                            label="Password"
                            label-for="input-password"
                          >
                            <b-form-input
                              id="input-password"
                              v-model="user.auth.password"
                              type="password"
                              placeholder="e.g. abc123"
                            ></b-form-input>
                          </b-form-group>
                          <b-form-group label="Family" label-for="input-family">
                            <b-form-select
                              v-model="user.db.family_key"
                              :options="familyList"
                            ></b-form-select>
                          </b-form-group>
                          <b-form-group
                            label="Stripe customer"
                            label-for="input-stripe"
                          >
                            <b-form-input
                              id="input-stripe"
                              v-model="user.db.stripe_customer_id"
                              type="text"
                              placeholder=""
                            ></b-form-input>
                          </b-form-group>
                        </div>
                      </b-tab>
                      <b-tab title="Permissions">
                        <div class="form-wrapper">
                          <b-form-group label="User Group" label-for="claims">
                            <b-list-group id="claims">
                              <b-list-group-item>
                                <b-form-checkbox
                                  v-model="user.auth.customClaims.admin"
                                  :value="true"
                                  :unchecked-value="false"
                                  switch
                                >
                                  Admin
                                </b-form-checkbox>
                              </b-list-group-item>
                              <b-list-group-item>
                                <b-form-checkbox
                                  v-model="user.auth.customClaims.vip"
                                  :value="true"
                                  :unchecked-value="false"
                                  switch
                                >
                                  VIP
                                </b-form-checkbox>
                              </b-list-group-item>
                              <b-list-group-item>
                                <b-form-checkbox
                                  v-model="user.auth.customClaims.premium"
                                  :value="true"
                                  :unchecked-value="false"
                                  switch
                                >
                                  Premium
                                </b-form-checkbox>
                              </b-list-group-item>
                            </b-list-group>
                          </b-form-group>
                          <b-form-group
                            id="roles"
                            label="Roles"
                            label-for="roles"
                          >
                            <b-list-group id="roles">
                              <b-list-group-item
                                class=""
                                v-for="role in roles"
                                :key="role['.key']"
                              >
                                <b-form-checkbox
                                  v-model="user.db.roles"
                                  :name="'role-' + role.name"
                                  :value="role['.key']"
                                  switch
                                >
                                  {{ role.name }}
                                </b-form-checkbox>
                                <p class="mx-1 my-1 text-muted">
                                  {{ role.description }}
                                </p>
                              </b-list-group-item>
                            </b-list-group>
                          </b-form-group>
                        </div>
                      </b-tab>
                      <b-tab title="Preferences">
                        <div class="form-wrapper">
                          <b-form-group>
                            <b-form-checkbox
                              v-model="user.db.profile.number_format"
                              switch
                            >
                              Show Ingredient Amounts as Fractions
                            </b-form-checkbox>
                          </b-form-group>
                          <b-form-group>
                            <b-form-checkbox
                              v-model="user.db.profile.news_enroll"
                              name="news_enroll"
                              :value="true"
                              class="mb-3"
                              switch
                            >
                              Newsletter Enrollment
                            </b-form-checkbox>
                          </b-form-group>
                        </div>
                      </b-tab>
                      <template #tabs-end>
                        <li
                          role="presentation"
                          class="nav-item align-self-center justify-content-end text-right"
                        >
                          <b-button
                            type="submit"
                            variant="outline-success"
                            :disabled="saving"
                            size="sm"
                            class="mr-1"
                          >
                            <span v-if="saving">
                              <b-spinner small></b-spinner>
                              <span class="sr-only">Saving...</span>
                            </span>
                            <span v-else>
                              <i class="far fa-save"></i>
                            </span>
                          </b-button>
                          <b-button
                            variant="outline-danger"
                            @click="closeEdit"
                            size="sm"
                            class="mr-1"
                            ><i class="far fa-window-close"></i
                          ></b-button>
                        </li>
                      </template>
                    </b-tabs>
                  </b-form>

                  <b-modal
                    id="confirmCreate"
                    title="Send Welcome Email?"
                    ok-title="Create"
                    @ok="create"
                  >
                    Do you want to send a welcome email to the new user?
                    <b-form-checkbox
                      v-model="user.auth.sendWelcome"
                      :value="true"
                      :unchecked-value="false"
                      class="mt-2"
                    >
                      Send Welcome Email
                    </b-form-checkbox>
                  </b-modal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import FirebaseMixin from "@/mixins/Firebase";
import UtilsMixin from "@/mixins/Utils";
import Loader from "@/components/Loader";
import Users from "@/firebase/users";

export default {
  name: "Users",
  mixins: [FirebaseMixin, UtilsMixin],
  components: {
    loader: Loader
  },
  data() {
    return {
      bind: [
        { ref: "familyIndex", target: "families" },
        { ref: "roles", target: "roles" }
      ],
      loading: true,
      saving: false,
      users: [],
      user: {},
      isNew: false,
      roles: [],
      families: {},
      fields: [
        {
          key: "auth.uid",
          label: "UID",
          sortable: false
        },
        {
          key: "auth.displayName",
          label: "Name",
          sortable: true
        },
        {
          key: "auth.email",
          label: "Email",
          sortable: true
        },
        {
          key: "attributes",
          label: "Attributes"
        },
        {
          key: "actions",
          label: "Actions"
        }
      ],
      showEdit: false,
      filters: {
        showAll: true,
        free: true,
        subscribers: true,
        admins: true
      },
      searchText: ""
    };
  },
  computed: {
    ...mapState(["isAuthenticated", "userProfile", "config"]),
    ...mapGetters(["searchConfig"]),
    freeUsers() {
      return this.users.filter(user => {
        return user.auth.customClaims.premium || user.auth.customClaims.admin
          ? false
          : true;
      }).length;
    },
    subscribers() {
      return this.users.filter(user => {
        return user.auth.customClaims.premium ? true : false;
      }).length;
    },
    admins() {
      return this.users.filter(user => {
        return user.auth.customClaims.admin ? true : false;
      }).length;
    },
    filteredUsers() {
      let users;
      if (this.searchText.length > 0) {
        const searchText = this.searchText.toLowerCase();
        users = this.users.filter(user => {
          return (
            user.auth.uid.toLowerCase().includes(searchText) ||
            user.auth.email.toLowerCase().includes(searchText) ||
            user.auth.displayName.toLowerCase().includes(searchText)
          );
        });
      } else {
        users = [...this.users];
      }
      if (this.filters.showAll) {
        return users;
      } else {
        return users.filter(user => {
          let showUser = false;
          if (
            this.filters.free &&
            !user.auth.customClaims.premium &&
            !user.auth.customClaims.admin
          ) {
            showUser = true;
          }
          if (this.filters.subscribers && user.auth.customClaims.premium) {
            showUser = true;
          }
          if (this.filters.admins && user.auth.customClaims.admin) {
            showUser = true;
          }
          return showUser;
        });
      }
    },
    familyList() {
      let options = new Array();
      options.push({
        value: null,
        text: "Create new family "
      });
      Object.keys(this.families).forEach(family => {
        let option = {
          value: family,
          text: this.families[family]
        };
        options.push(option);
      });
      return options;
    }
  },
  watch: {
    users: {
      immediate: true,
      handler(users) {
        users.forEach((user, index) => {
          if (
            !user.auth.customClaims ||
            typeof user.auth.customClaims !== "object"
          ) {
            this.users[index].auth.customClaims = {};
          }
          if (!user.db.profile || typeof user.db.profile !== "object") {
            this.users[index].db.profile = {};
          }
          if (!Array.isArray(user.db.roles)) {
            this.users[index].db.roles = [];
          }
        });
      }
    }
  },
  methods: {
    getUsers() {
      this.loading = true;
      const u = new Users();
      u.getUsers()
        .then(resp => {
          this.users = [...resp.data.users];
          this.loading = false;
        })
        .catch(error => {
          console.error("Unable to fetch users", error);
          this.loading = false;
        });
    },
    edit(user) {
      this.isNew = false;
      this.user = { ...user };
      this.showEdit = true;
    },
    closeEdit() {
      this.user = {};
      this.showEdit = false;
    },
    newUser() {
      this.isNew = true;
      this.user = { auth: {}, db: { profile: {}, roles: {} } };
      this.showEdit = true;
    },
    create() {
      this.saving = true;
      const user = { ...this.user };
      console.log("User", user);
      const u = new Users();
      u.addUser(user)
        .then(() => {
          this.saving = false;
          this.closeEdit();
          window.toastr.success("User updated successfully.");
          this.getUsers();
        })
        .catch(error => {
          this.saving = false;
          window.toastr.error("An error occurred creating the user: " + error);
          console.error("Unable to create user", error);
        });
      return true;
    },
    update() {
      this.saving = true;
      const user = { ...this.user };
      console.log("User", user);
      const u = new Users();
      u.updateUser(user.auth.uid, user)
        .then(() => {
          this.saving = false;
          window.toastr.success("User updated successfully.");
          this.getUsers();
        })
        .catch(error => {
          this.saving = false;
          window.toastr.error("An error occurred updating the user: " + error);
          console.error("Unable to update user", user.auth.uid, error);
        });
      return true;
    },
    deleteUser(uid) {
      this.saving = true;
      const u = new Users();
      u.deleteUser(uid)
        .then(() => {
          this.saving = false;
          window.toastr.success("User deleted successfully.");
          this.users.forEach((user, index) => {
            if (user.auth.uid === uid) {
              this.users.splice(index, 1);
            }
          });
        })
        .catch(error => {
          this.saving = false;
          window.toastr.error("An error occurred deleting the user: " + error);
          console.error("Unable to delete user", uid, error);
        });
      return true;
    },
    selectAll() {
      for (let filter in this.filters) {
        if (filter !== "showAll") {
          this.filters[filter] = this.filters.showAll;
        }
      }
    },
    handleFilterSelect() {
      let showAll = true;
      for (let filter in this.filters) {
        if (!this.filters[filter] && filter !== "showAll") {
          showAll = false;
        }
      }
      this.filters.showAll = showAll;
    }
  },
  created() {
    this.getUsers();
  }
};
</script>

<style scoped>
.card-shadow {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -4px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.background-none {
  background: none;
}

.profile-tab {
  max-width: 600px;
}
</style>
